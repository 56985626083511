/*! CSS Used from: https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css */
*,
::after,
::before {
	box-sizing: border-box;
}
h2 {
	margin-top: 0%;
	margin-bottom: 0.5rem;
}
button {
	border-radius: 0;
	background-color: #17a2b8;
}
.button:focus {
	outline: none;
  }

button {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	background-color: #17a2b8;
}
button {
	overflow: hidden;
}
button {
	text-transform: none;
	background-color: #17a2b8;
}
[type="button"],
button {
	-webkit-appearance: initial;
	
	border-radius: 7px;
	transition: all .5s ease;
    color: white;
   border:none;
    font-family:'Montserrat', sans-serif;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    font-size: 17px;
    background-color : transparent;
    padding: 16px;
    outline: none;
    border-radius: 4px;
	opacity: 30%;
}
button:hover {
   border: none;
	opacity: 70%;
}
[type="button"]::-moz-focus-inner,
button::-moz-focus-inner {
	padding: 0;
	border-style: none;
}
h2 {
	margin-bottom: 0,5rem;
	
}
h2 {
	font-size: 2rem;
}
.container {
	
	justify-content: center;
	position: auto;
	margin: 0 auto;
	text-align: center;
	width: 100%;
	
	margin-right: auto;
	margin-left: auto;
}
@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
}
@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
}
@media (min-width: 992px) {
	.container {
		max-width: 960px;
	}
}
@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
	}
}
@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
}
@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
}
@media (min-width: 992px) {
	.container {
		max-width: 960px;
	}
	.button1{
		position: fixed;
		top: 50%;
		left: 0%;
	}
	.button2{
		
		top: 50%;
		right: 0%;
		position: fixed;
	}
	
}
@media (min-width: 1200px) {
	.container {
		max-width: 1140px;
	}
	.button1{
		position: fixed;
		top: 50%;
		left: 0%;
	}
	.button2{
		position: fixed;
		top: 50%;
		right: 0%;
		
	}
	.back3{
		display: none;
	}
}
.btn {
	display: inline-block;
	font-weight: 400;
	color: #0a0657;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
	.btn {
		transition: none;
	}
}
.btn:hover {
	color: #ffffff;
	text-decoration: none;
	border: none;
}
.btn:focus {
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.btn:disabled {
	opacity: 0.65;
}
.btn-primary {
	color: #fff;
	background-color: #ffffff;
	border-color: #ffffff;
}
.btn-primary:hover {
	color: #fff;
	background-color: #ffffff;
	border-color: #ffffff;
}
.btn-primary:focus {
	color: #fff;
	background-color: #ffffff;
	border-color: #ffffff;
	box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-primary:disabled {
	color: #fff;
	background-color: #ffffff;
	border-color: #ffffff;
}
.btn-secondary {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
}
.btn-secondary:hover {
	color: #fff;
	background-color: #5a6268;
	border-color: #545b62;
}
.btn-secondary:focus {
	color: #fff;
	background-color: #5a6268;
	border-color: #545b62;
	box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary:disabled {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
}
.btn-info {
	color: #fff;
	background-color: #ffffff;
	border-color: #ffffff;
}
.btn-info:hover {
	color: #fff;
	background-color: #ffffff;
	border-color: #ffffff;
}
.btn-info:focus {
	color: #fff;
	background-color: #ffffff;
	border-color: #ffffff;
	box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}
.btn-info:disabled {
	color: #fff;
	background-color: #ffffff;
	border-color: #ffffff;
}
.btn-sm {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}
.btn-group {
	position: relative;
	display: -ms-inline-flexbox;
	display: inline-flex;
	vertical-align: middle;
}
.btn-group > .btn {
	position: relative;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
}
.btn-group > .btn:hover {
	z-index: 1;
}
.btn-group > .btn:active,
.btn-group > .btn:focus {
	z-index: 1;
}
.btn-group > .btn:not(:first-child) {
	margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.bg-light {
	background-color: #f8f9fa !important;
}
.overflow-hidden {
	overflow: hidden !important;
}
.mb-2 {
	margin-bottom: 0rem !important;
}
.mt-3 {
	margin-top: 0rem !important;
}
.mb-3 {
	margin-bottom: 0rem !important;
}
.pt-3 {
	padding-top: 0rem !important;
}
.pb-3 {
	padding-bottom: 0rem !important;
}
.text-center {
	text-align: center !important;
}
@media print {
	*,
	::after,
	::before {
		text-shadow: none !important;
		box-shadow: none !important;
	}
	h2 {
		orphans: 3;
		widows: 3;
	}
	h2 {
		page-break-after: avoid;
	}
	.container {
		min-width: 992px !important;
	}
}
/*! CSS Used from: https://nodlik.github.io/StPageFlip/static/style.css */
.demo-block {
	border-top: solid 1px #dcdcdc;
	border-bottom: solid 1px #dcdcdc;
	overflow: hidden;
}
.flip-book {
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
	margin: 0 auto;
	display: none;
}
.page {
	top: 50%;
	padding: 20px;
	background-color: #fdfaf7;
	color: #785e3a;
	border: solid 1px #c2b5a3;
	overflow: hidden;
}
.page .page-content {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
}
.page .page-content .page-header {
	height: 30px;
	font-size: 100%;
	text-transform: uppercase;
	text-align: center;
}
.page .page-content .page-image {
	height: 50%;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	scale: 0.1;
}

.page .page-content .page-footer {
	height: 50px;
	border-top: solid 1px #f4e8d7;
	font-size: 100%;
	color: #998466;
}
.page.--left {
	border-right: 0;
	box-shadow: inset -7px 0 30px -7px rgba(0, 0, 0, 0.4);
}
.page.--right {
	border-left: 0;
	box-shadow: inset 7px 0 30px -7px rgba(0, 0, 0, 0.4);
}
.page.--right .page-footer {
	text-align: right;
}
/*! CSS Used from: Embedded */
.stf__wrapper {
	position: relative;
	display: block;
	box-sizing: border-box;
	transform: translateZ(0);
}
.stf__wrapper canvas {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}
.stf__block {

	position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    perspective: 2000px;
}

.stf__item {
	margin: 0 auto;
	display: none;
	position: absolute;
}


.custom-zoom [data-rmiz-modal-overlay],
.custom-zoom [data-rmiz-modal-img] {
  transition-duration: 0.8s;
  transition-timing-function: linear;
}
.custom-zoom [data-rmiz-modal-overlay="hidden"] {
  background-color: rgba(255, 255, 255, 0);
}
.custom-zoom [data-rmiz-modal-overlay="visible"] {
  background-color: rgb(255, 255, 255);
}
.custom-zoom [data-rmiz-btn-unzoom] {
  background-color: #bd93f9;
  color: #000;
}
.custom-zoom [data-rmiz-btn-unzoom]:focus-visible {
  outline-offset: 0.4rem;
  outline: 0.2rem solid #bd93f9;
}

img{
	display: block;
	margin: 0 auto;
	
}

.block{
	text-align: center;
	
	object-fit: cover;
	justify-content: center;

}
.stf__parent{
	justify-items: center;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	position: center;
	
}
@media (min-width:320px)  { 
	.button1{

	}/* smartphones, iPhone, portrait 480x320 phones */ }
@media (min-width:340px)  {.back3{
	
	
} 
.button1{
	position: fixed;
	top: 50%;
	left: 0%;
	overflow: hidden;
}
.button2{
	position: fixed;
	top: 50%;
	right: 0%;
	overflow: hidden;
	
}/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media (min-width:641px)  {
	.button1{
	position: fixed;
	top: 50%;
	left: 0%;
}
.button2{
	position: fixed;
	top: 50%;
	right: 0%;
	
}/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px)  { 
	.button1{
	position: fixed;
	top: 50%;
	left: 0%;
}
.button2{
	position: fixed;
	top: 50%;
	right: 0%;
	
}
/* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1000px) {.back3{
	display: none;
	position: fixed;
} 
.button1{
	position: fixed;
	top: 50%;
	left: 0%;
}
.button2{
	position: fixed;
	top: 50%;
	right: 0%;
	
}/* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) {.back3{
	display: none;
	position: fixed;
}
	.button1{
		
	}
	 /* hi-res laptops and desktops */ }

	.Bottom2{
		position: fixed;
		bottom: 0%;
		margin: 0 auto;
		text-align: center;
		justify-content: center;
		left: 30%;
		right: 30%;
		color: #785e3a;
		font-family:'Roboto-mono', sans-serif;
		text-transform: uppercase;
		text-align: center;
		
		font-size: 17px;
		
		
	
	}

	.back3{
		padding-top: 5px;
	}

	.button1{
		position: fixed;
		top: 40%;
		left: 0%;
		overflow: hidden;
	}
	.button2{
		position: fixed;
		top: 40%;
		right: 0%;
		overflow: hidden;
		
	}